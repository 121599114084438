import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from 'react-helmet'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {Link} from "gatsby"
import Footer from '../components/Footer'

// markup
const TermsPage = () => {

    const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)



  return (
    <main>
      <Helmet title="Heyday abonementa noteikumi" defer={false} />



      <div class="container content">

      <section class="section">

          <div className="columns is-mobile">
              <div className="column"><Link to="/"><Img fixed={data.logo.childImageSharp.fixed} alt="Heyday" /> </Link></div>
              <div className="column is-narrow"><Link to="/" class="button is-rounded is-outline">Atpakaļ</Link></div>
          </div>


        <h1 class="title is-1">Noteikumi</h1>

        <p>Publicēti 2021. gada 18. februārī</p>

<p class="title is-6">1.	Vispārējie lietošanas noteikumi</p>
<p>Mēs paturam tiesības, pēc saviem ieskatiem mainīt šos lietošanas noteikumus. Pēc izmaiņu veikšanas ievietojot tos mājaslapā www.heyday.lv. Ievietotās izmaiņas stājas spēkā ar to publicēšanas brīdi. Lietotāju ērtībai, šīs lapas augšdaļā ir iekļauts datums, kad veiktas pēdējās izmaiņas. Ja lietošanas noteikumos tiks veiktas būtiskas izmaiņas, klienti par to tiks informēti pa norādīto epastu, kā arī ievietojot pamanāmu paziņojumu mājaslapā, tomēr tā ir paša lietotāja atbildība laiku pa laikam pārskatīt lietošanas noteikumus un līgumu. Jūsu turpmākā heyday.lv vietnes un/vai produktu un pakalpojumu izmantošana pēc jebkādām izmaiņām tiks uzskatīta par jūsu piekrišanu veiktajām izmaiņām lietošanas noteikumos. Ja jūs nepiekrītat pašreizējai lietošanas noteikumu vai konfidencialitātes paziņojuma versijai, lūdzu nekavējoties pārtrauciet vietnes izmantošana, beidzat jebkādu Produktu un pakalpojumu pasūtīšana un abonēšanas pakalpojumu izmantošanu.
Ja kādi nosacījumi tiek uzskatīti par nederīgiem, spēkā neesošiem vai kāda iemesla dēļ nav izpildāmi, šo nosacījumu vai termiņu uzskata par nošķiramu, un tas neietekmē neviena atlikušā nosacījuma vai termiņa derīgumu un izpildāmību. 
Visi jautājumi, kas saistīti ar jebkādu piedāvājumu vai vienošanos par preču pirkšanu un pārdošanu no mūsu vietnes tiek regulēti saskaņā ar Latvijas Republikas likumdošanu. 
</p>
<p>Saimnieciskās darbības veicējs SIA Unicorns Unlimited, juridiskā adrese Tālivalža iela 14-1, Rīga, LV-1006, reģistrācijas numurs 40203274222, PVN maksātāja numurs LV40203274222, saimnieciskās darbības veikšanas faktiskā adrese ir interneta vietne heyday.lv. Kontaktinformācija info@heyday.lv vai +371 2208 3434.</p>

<p class="title is-6">2.	Privātuma politika</p> 
<p>Lietojot Heyday pakalpojumus, lietotājs piekrīt privātuma politkai. Privātuma politiku Heyday publicē adresē <Link to="/privacy">heyday.lv/privacy</Link> </p>
<p class="title is-6">3.	Lietošanas noteikumu grozījumi vai izmaiņas</p>
<p>Uzņēmums patur tiesības laiku pa laikam veikt izmaiņas lietošanas noteikumos un privātuma politikā, par to informējot mājaslapā heyday.lv.</p>
<p class="title is-6">4.	Heyday.lv lietotāja konta izveide un lietošana</p>
<p>Lai iegādātos Heyday.lv sniegtos pakalpojumus, jums būs jāizveido klienta konts, kā arī jums ir jābūt derīgai maksājuma metodei, kas ir saistīta ar jūsu “kontu”.
Lai aizsargātu savu kontu, jums tiks piedāvāts izveidot paroli. Izvēlieties drošu paroli un glabājiet paroli konfidenciālu. Jūs esat atbildīgs par sava konta konfidencialitātes uzturēšanu, par visām darbībām, kas notiek jūsu kontā vai caur to, un par piekļuves ierobežošanu jūsu datoram (-iem) vai ierīcei (-ēm). Ja uzzināt par neatļautu paroles vai konta izmantošanu, nekavējoties sazinieties ar mums. Jūsu konts ir paredzēts tikai jūsu lietošanai. Jūs piekrītat neiznomāt, nepiešķirt, nepārdot, nepārsūtīt, īrēt un nenodrošināt tiešu vai netiešu piekļuvi savam kontam. 
Jūs piekrītat, ka visa jūsu iesniegtā informācija saistībā ar jūsu kontu ir patiesa un pareiza. Jūs nevarat izveidot vairākus vai viltotus kontus vietnē Heyday.lv izmantojot vairākas vai viltotas e-pasta adreses vai identitātes. Katram klientam jābūt tikai vienam Heyday.lv kontam. Uzņēmums patur tiesības pēc saviem ieskatiem atteikt pakalpojumu, pārtraukt jūsu konta apkalpošanu, pārtraukt jūsu tiesības uz vietni vai produktiem un pakalpojumiem, noņemt vai rediģēt saturu vai atcelt pasūtījumus. 
</p>
<p class="title is-6">5.	Produkti un pakalpojumi</p>
<p>Ziedu piegāde tiek veikta vienu reizi nedēļā, konkrētā dienā, kurjera piegādes laiku iepriekš saskaņojot ar klientu.
Mēs paturam tiesības mainīt visu vai daļu satura vai iepakojumu, lai bez iepriekšēja brīdinājuma piegādātu ziedus ar līdzvērtīgu vai lielāku vērtību. Visi produkti ir atkarīgi no pieejamības. Lai nodrošinātu ilgāku mūžu, produktus var piegādāt pumpuros. Piegādāto augu un ziedu saturs nav paredzēts ēšanai, kā arī tas var izraisīt ādas kairinājumu. Lūdzu, sazinieties ar mums, ja pirms pasūtījuma iesniegšanas jums nepieciešama papildu informācija. Mūsu rozes tiek sūtītas ar neskartām ārējām aizsargziedlapām un rožu ērkšķiem, lai pasargātu ziedus tranzīta laikā. Pēc saņemšanas, lūdzu, noņemiet visas brūnās, tumšās vai krāsu mainījušās ārējās aizsargziedlapas. 
</p>
<p class="title is-6">6.	Cenu politika</p>
<p>Pirmreizējā apmaksa par ziedu abonementu tiek veikta reģistrācijas laikā, saskaņā ar heyday.lv norādītajā cenām. Apmaksa tiek veikta par vienu, nākošo ziedu piegādes reizi.
Atkārtotā apmaksa tiks veikta automātiski dažas dienas vai stundas pirms piegādes, atkarībā no sistēmas noslodzes. Ja vēlaties uzzināt, konkrētu laiku, kad notiks automātiskā ziedu apmaksa, tieši jums, lūdzu sazinieties ar mūsu klientu servisu rakstot uz info@heyday.lv vai zvanot.
Ziedu piegāde notiek ar kurjera palīdzību un ziedi tiks piegādāti līdz pasūtījumā norādītajai adresei. Ziedu abonementa piegāde Rīgas teritorijā ir bezmaksas. Jūrmalā, Piņķos, Mārupē, Jaunmārupē, Berģos, Baltezerā, Ādažos, Carnikavā piegādes maksa ir 5 EUR un tiks pievienota jūsu pasūtījumam. Par piegādi uz citām Latvijas teritorijām lūdzu sazinieties ar mūsu klientu servisu, rakstot info@heyday.lv 
</p>
<p class="title is-6">7.	Pasūtījumu kārtība un maksājumi </p>
<p>Lai pasūtījums tiktu apstrādāts un piegādāts vajadzīgajā datumā, jums mums ir jāiesniedz visa attiecīgā saņēmēja informācija, ieskaitot pilnīgu piegādes informāciju. Veicot pasūtījumu pie mums, jūs apstiprināt, ka esat vecāks par 18 gadiem. Jūsu pasūtījums tiks apstrādāts tikai tad, ja tiks sniegta visa maksājuma informācija. Lai saņemtu pasūtījumu tekošajā nedēļā, pasūtījums jāveic ne vēlāk kā līdz tekošās nedēļas otrdienai 23:59. </p>
<p>Visus vietnē heyday.lv veiktos pasūtījumus apstrādā Stripe platforma. Jūsu pasūtījums tiks apstiprināts pa e-pastu, tādēļ tā ir lietotāja atbildība norādīt derīgu un pareizu epasta informāciju. Ziedu piegādes informācija tiks nosūtīta uz jūsu norādīto telefona numuru. </p>
<p>Maksājumu var veikt, izmantojot jebkuru no heyday.lv lapā norādītajām apmaksas metodēm, no tās tiks debetēts, veicot pasūtījumu. Ja kartes sniedzējs noraida, kādu no maksājumam piedāvātajiem veidiem, piemēram, kredītkartes, debetkartes, mēs pieliksim visas pūles, lai sazinātos ar jums pa norādīto telefona numuru, lai atrastu alternatīvu maksājuma veidu. Ja kāda iemesla dēļ, mēs nevaram ar jums sazināties un netiek sniegts cits maksājuma veids, pasūtījums tiks atcelts. </p>
<p>Pirmreizējā apmaksa par ziedu abonementu tiek veikta reģistrācijas laikā, saskaņā ar heyday.lv norādītajā cenām. Apmaksa par nākošo ziedu piegādes reizi, tiks veikta automātiski pirms piegādes. Visas cenas tiek parādītas mūsu vietnē. Mēs darām visu iespējamo, lai pārliecinātos, ka cenas tiek parādītas pareizi. Cenu izmaiņu, kurjer piegādes vai PVN likmes maiņas dēļ ir iespējama pakalpojuma cenas maiņa, kā rezultātā mēs labosim produktu un pakalpojumu cenas un lūgsim jūs apstiprināt izmaiņas vai atcelt pakalpojumu.</p>
<p class="title is-6">8.	Ziedu atgriešana un nomaiņa </p>
<p>
Ātri bojājošās dabas dēļ, sūdzības par saņemtajiem ziediem ir iespējamas 2 dienu laikā pēc to saņemšanas. Ja 2 dienu laikā pēc piegādes ziedi novīst vai aiziet bojā, lūdzu sūties bildes ar ziediem uz epastu info@heyday.lv, norādot savu vārdu, uzvārdu, telefona numuru un epastu. 
Ja ziedi tiek saņemti jau bojātā stāvoklī, mēs nodrošināsim to aizvietošanu. Lūdzu atsūtiet bildes ar bojātajiem ziediem ne vēlāk kā 2 dienu laikā kopš saņemšanas, uz epastu info@heyday.lv, norādot savu vārdu, uzvārdu, telefona numuru un epastu.
Ziedu nomaiņa un naudas atmaksa tiks izskatīta katrā gadījumā atsevišķi. 
</p>
<p class="title is-6">9.	Elektroniskā komunikācija</p>
<p>Apmeklējot heyday.lv lapu, izmantojot piedāvātos heyday.lv produktus un pakalpojumus vai komunicējot ar mums e-pastā, jūs sazināties ar mums elektroniski. Tādējādi jūs piekrītat saņemt paziņojumus no mums elektroniski. Mēs sazināsimies ar jums, nosūtot e-pastus uz reģistrācijas laikā norādīto epasta adresi un telefona numuru. Jūs arī piekrītat saņemt no mums cita veida saziņu, piemēram, īpašos piedāvājumus, reklāmas sludinājumus un klientu aptaujas.</p>
<p class="title is-6">10.	Atcelšana</p>
<p>Ziedu abonementa atcelšana un maiņa ir bez maksas un reižu ierobežojuma. Ziedu abonementa atcelšana iespējama ne vēlāk kā vienu (1) nedēļu pirms nākošās ziedu abonementa piegādes reizes, tādējādi vēl nebūs veikta priekšapmaksa par nākošo ziedu piegādes reizi.
</p>
<p>
Ziedu abonementa maiņa iespējama līdz 48 stundām pirms piegādes, tomēr pīķa laikā pasūtījumi tiek apstrādāti līdz pat 3 dienām, un tāpēc šajā laikā izmaiņas var arī netikt apstrādātas.
</p>
<p class="title is-6">11.	Mūsu atcelšanas tiesības</p>
<p>Mēs paturam tiesības atcelt līgumu starp mums, ja:
(1) Mums nav pietiekami daudz ziedu krājumu, kas radušies no mums neatkarīgu un neietekmējamu apstākļu dēļ; (2) Produktu cena vai apraksts tika nepareizi norādīts drukas kļūdas vai kļūdas dēļ cenu informācijā, ko saņēmām no mūsu piegādātājiem; (3) kādu citu iemeslu dēļ, mēs nevaram izpildīt pakalpojuma piegādi, tādēļ mēs bez iepriekšēja brīdinājuma varam pārtraukt sadarbību. Gadījumā, ja mēs atcelsim jūsu ziedu piegādi, mēs jums par to paziņosim pa e-pastu un atmaksāsim visu jūsu samaksāto summu uz jūsu kredītkarti vai debetkarti. 
Mūsu pienākums nebūs jums sniegt nekāda veida kompensāciju. 
</p>
<p class="title is-6">12.	Ziedu piegādes traucējumi</p>
<p>Klienta atbildība ir atrasties mājas sarunātajā piegādes laikā un adresē. Ja kurjeram ierodoties norādītajā adresē, saņēmējs nav mājās vai neatver durvis, tad kurjers centīsies sazināties nākošās 5 minūtes, kopš ierašanās, bet pēc norādītā laika kurjers dosies prom. Šajā gadījumā piegādes izmaksas netiek apmaksātas un ziedus ir iespējams saņemt Rīgā, vispirms sazinoties ar klientu servisu info@heyday.lv un vienojoties par laiku un vietu.</p>

<div class="box">
        <p class="title is-6">Kur meklēt palīdzību?</p>
        <p class="">Droši raksti mums uz adresi <a href="mailto:info@heyday.lv">info@heyday.lv</a> vai <a href="call:+37122083434">zvani</a>.</p>
</div>




            <div className="columns is-mobile mt-4">
              <div className="column is-narrow"><Link to="/" class="button is-rounded is-primary">Atpakaļ</Link></div>
              <div className="column"></div>
          </div>




        </section>

      </div>




        <Footer />
    </main>
  )
}

export default TermsPage
